import {
  lightgreen_ellipse,
  purple_ellipse,
  yellow_ellipse,
} from "assets/icons/icons";
import { laptop } from "assets/images/images";
import { Features } from "constant/features";

const ChooseUs: React.FC = () => {
  return (
    <div id="contact_us" className="myContainer lg:!py-36 !py-16">
      <div className="flex flex-col items-center">
        <p className="text-primary">Why Choose Us?</p>
        <p className="semibold lg:text-5xl text-3xl md:w-[750px] lg:leading-[70px] leading-10 mt-5 text-center">
          Choose No Ransom Shield for Unrivaled Data Security
        </p>
        <p className="lg:text-lg text-black-500 regular mt-5 md:w-[650px] text-center">
          Empowering Your Defense: Trust, Innovation, and Proven Resilience in
          the Face of Cyber Threats
        </p>
      </div>
      <div className="mt-14 grid md:grid-cols-6 grid-cols-3 gap-7">
        <div className="col-span-3 flex justify-center">
          <div className="flex items-center xl:w-[70%] p-6 shadow-lg">
            <img src={Features[0].icon} alt="icon" />
            <div className="ml-5">
              <p className="semibold md:text-2xl text-lg">
                {Features[0].title}
              </p>
              <p className="text-black-500 regular text-xs md:text-sm mt-2">
                {Features[0].description}
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-3 flex justify-center">
          <div className="flex items-center xl:w-[70%] p-6 shadow-lg">
            <img src={Features[1].icon} alt="icon" />
            <div className="ml-5">
              <p className="semibold md:text-2xl text-lg">
                {Features[1].title}
              </p>
              <p className="text-black-500 regular text-xs md:text-sm mt-2">
                {Features[1].description}
              </p>
            </div>
          </div>
        </div>
        <div className="xl:col-span-2 col-span-3 flex items-center">
          <div className="flex items-center p-6 shadow-lg">
            <img src={Features[2].icon} alt="icon" />
            <div className="ml-5">
              <p className="semibold md:text-2xl text-lg">
                {Features[2].title}
              </p>
              <p className="text-black-500 regular text-xs md:text-sm mt-2">
                {Features[2].description}
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-2 hidden xl:block">
          <div className="relative">
            <div className="absolute -left-48">
              <img src={purple_ellipse} alt="ellipse" />
            </div>
            <div className="absolute right-40 -top-16">
              <img src={lightgreen_ellipse} alt="ellipse" />
            </div>
            <div className="absolute -right-28 top-6">
              <img src={yellow_ellipse} alt="ellipse" />
            </div>
          </div>
          <img src={laptop} alt="laptop" />
          <div className="relative">
            <div className="absolute -left-48 -bottom-4">
              <img src={yellow_ellipse} alt="ellipse" />
            </div>
            <div className="absolute left-32 -bottom-28">
              <img src={lightgreen_ellipse} alt="ellipse" />
            </div>
            <div className="absolute -right-32 -bottom-3">
              <img src={purple_ellipse} alt="ellipse" />
            </div>
          </div>
        </div>
        <div className="xl:col-span-2 col-span-3 flex items-center">
          <div className="flex items-center p-6 shadow-lg">
            <img src={Features[3].icon} alt="icon" />
            <div className="ml-5">
              <p className="semibold md:text-2xl text-lg">
                {Features[3].title}
              </p>
              <p className="text-black-500 regular text-xs md:text-sm mt-2">
                {Features[3].description}
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-3 flex justify-center">
          <div className="flex items-center xl:w-[70%] p-6 shadow-lg">
            <img src={Features[4].icon} alt="icon" />
            <div className="ml-5">
              <p className="semibold md:text-2xl text-lg">
                {Features[4].title}
              </p>
              <p className="text-black-500 regular text-xs md:text-sm mt-2">
                {Features[4].description}
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-3 flex justify-center">
          <div className="flex items-center xl:w-[70%] p-6 shadow-lg">
            <img src={Features[5].icon} alt="icon" />
            <div className="ml-5">
              <p className="semibold md:text-2xl text-lg">
                {Features[5].title}
              </p>
              <p className="text-black-500 regular text-xs md:text-sm mt-2">
                {Features[5].description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseUs;
