import { useState } from "react";
import { cross_icon, menu_icon } from "assets/icons/icons";
import { logo } from "assets/images/images";
import { ThemeButton } from "components/components";
import { handleClick } from "helpers/helpers";
import "./navbar.css";

const MobNavBar: React.FC = () => {
  const [showOptions, setShowOptions] = useState("headerClose");
  const handleOptions = () => {
    if (showOptions === "headerClose") {
      setShowOptions("headerOpen");
    } else {
      setShowOptions("headerClose");
    }
  };
  return (
    <div className="lg:hidden z-10 sticky top-0 bg-white shadow-md">
      <div className="flex items-center justify-between myContainer">
        <a href="#">
          <img alt="logo" src={logo} width={100} />
        </a>
        <img
          onClick={handleOptions}
          alt="menu_icon"
          src={showOptions === "headerClose" ? menu_icon : cross_icon}
          width={25}
          className="cursor-pointer"
        />
      </div>
      <div
        className={`${showOptions} absolute w-full !py-0 myContainer bg-white overflow-hidden`}
      >
        <div className="">
          <div className="gap-5 flex flex-col items-center">
            <a href="#" onClick={handleOptions}>
              Home
            </a>
            <a href="#about" onClick={handleOptions}>
              About Us
            </a>
            <a href="#pricing" onClick={handleOptions}>
              Pricing
            </a>
            <a href="#contact_us" onClick={handleOptions}>
              Contact Us
            </a>
          </div>
          <div className="flex flex-col justify-center">
            <ThemeButton
              bgFill
              content="Login"
              className="mt-5"
              onClick={handleClick}
            />
            <ThemeButton
              content="Sign Up"
              className="mt-6"
              onClick={handleClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const NavBar: React.FC = () => {
  return (
    <>
      <MobNavBar />
      <div className="myContainer sticky top-0 z-10 bg-white shadow-md lg:flex items-center justify-between hidden">
        <a href="#">
          <img alt="logo" src={logo} width={130} />
        </a>
        <div className="gap-10 flex">
          <a href="#">Home</a>
          <a href="#about">About Us</a>
          <a href="#pricing">Pricing</a>
          <a href="#contact_us">Contact Us</a>
        </div>
        <div>
          <ThemeButton bgFill content="Login" onClick={handleClick} />
          <ThemeButton
            content="Sign Up"
            className="ml-7"
            onClick={handleClick}
          />
        </div>
      </div>
    </>
  );
};

export default NavBar;
