import { right_arrow } from "assets/icons/icons";
import { about_ellipsegroup, about_section_image } from "assets/images/images";
import { ThemeButton } from "components/components";
import { handleClick } from "helpers/helpers";

const About: React.FC = () => {
  return (
    <>
      {/* <div className="relative">
        <div className="absolute">
          <img alt="dots" src={about_ellipsegroup} />
        </div>
      </div> */}
      <div
        id="about"
        className="myContainer lg:!py-36 !py-16 grid lg:grid-cols-2 gap-10"
      >
        <div className="bg-[#EEEBF7] p-5 rounded-[40px]">
          <img alt="about_us" src={about_section_image} width={"100%"} />
        </div>
        <div className="flex flex-col justify-center">
          <p className="text-primary">About us</p>
          <p className="semibold lg:text-5xl text-3xl mt-5 lg:leading-[70px] leading-10">
            <span className="text-primary">No Ransom Technology:</span> Beyond
            Cybersecurity, Into Cyber Resilience
          </p>
          <p className="lg:text-lg text-black-500 regular mt-5">
            Welcome to “No Ransom Technology”, where cutting-edge technology
            meets unyielding data security. Our Trusted Execution Environment
            ensures your sensitive information remains impervious to hackers,
            providing an unmatched layer of protection before, during, and after
            a ransomware attack.
          </p>
          <div>
            <ThemeButton
              bgFill
              onClick={handleClick}
              content={
                <div className="flex">
                  <p>Lean More</p>
                  <img src={right_arrow} alt="arrow" className="ml-3" />
                </div>
              }
              className="mt-10"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
