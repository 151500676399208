import {
  covered_ellipse,
  lightgreen_ellipse,
  purple_ellipse,
  scanning_icon,
  security_icon,
  yellow_ellipse,
} from "assets/icons/icons";
import { home_section_image } from "assets/images/images";
import { ThemeButton } from "components/components";
import { handleClick } from "helpers/helpers";

const HomeSection: React.FC = () => {
  return (
    <div className="myContainer grid lg:grid-cols-2 lg:gap-5 gap-20 lg:!py-36 !py-16">
      <div>
        <p className="bold lg:text-6xl text-4xl lg:leading-[70px] leading-10">
          Use Our Product And{" "}
          <span className="text-primary">Never Pay Ransom Agains</span>
        </p>
        <p className="lg:text-lg text-black-500 regular mt-5">
          Defend against ransomware with No Ransom Technology. Our advanced SaaS
          solutions keep your digital assets secure, so you can focus on
          business, not threats.
        </p>
        <div className="mt-10">
          <ThemeButton bgFill content="Try For Free" onClick={handleClick} />
          <ThemeButton
            content="Buy Now"
            className="ml-5"
            onClick={handleClick}
          />
        </div>
      </div>
      <div className="flex justify-center">
        <div className="relative">
          <div className="-left-10 lg:-top-10 -top-5 absolute">
            <img alt="ellipse" src={purple_ellipse} />
          </div>
        </div>
        <div className="relative">
          <div className="bg-white lg:h-36 h-20 lg:w-36 w-20 flex items-center lg:-left-24 -left-10 top-16 justify-center rounded-3xl absolute">
            <img
              alt="security"
              src={security_icon}
              className="w-10 lg:w-auto"
            />
          </div>
        </div>
        <img
          alt="home_section_image"
          src={home_section_image}
          className="rounded-[40px] overflow-hidden lg:w-full w-4/5"
        />
        <div className="relative hidden lg:block">
          <div className="-right-16 top-44 absolute">
            <img alt="ellipse" src={lightgreen_ellipse} />
          </div>
        </div>
        <div className="relative">
          <div className="bg-purple-400 p-4 w-48 flex items-center lg:-right-24 -right-10 lg:top-8 bottom-8 lg:bottom-auto rounded-xl absolute">
            <img
              alt="scanning"
              src={scanning_icon}
              className="w-10 lg:w-auto"
            />
            <div className="ml-5">
              <p className="bold lg:text-2xl text-white">100%</p>
              <p className="regular lg:text-sm text-xs text-white">
                Virus scanning
              </p>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="-left-40 lg:-bottom-12 -bottom-10 absolute">
            <img alt="ellipse" src={yellow_ellipse} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSection;
