import { right_purple_arrow } from "assets/icons/icons";
import {
  banner_ellipsegroup_1,
  banner_ellipsegroup_2,
} from "assets/images/images";
import { ThemeButton } from "components/components";
import { handleClick } from "helpers/helpers";

const Banner: React.FC = () => {
  return (
    <div className="myContainer !pb-20">
      <div className="relative hidden md:block">
        <div className="absolute top-0 right-10">
          <img src={banner_ellipsegroup_2} alt="ellipse_group" />
        </div>
      </div>
      <div className="bg-primary md:py-14 py-7 px-3 rounded-3xl text-white text-center">
        <p className="semibold md:text-5xl text-2xl">
          Check Out Our Completely Free Plan
        </p>
        <div className="flex justify-center mt-6">
          <p className="regular md:text-lg lg:w-[50%] md:w-[70%]">
            Experience top-tier cybersecurity at no cost. No Ransom Shield's
            Completely Free plan offers essential features for robust data
            protection.
          </p>
        </div>
        <ThemeButton
          onClick={handleClick}
          className="md:mt-12 mt-5"
          content={
            <div className="flex">
              <p>Get It Now</p>
              <img src={right_purple_arrow} alt="arrow" className="ml-3" />
            </div>
          }
        />
      </div>
      <div className="relative hidden md:block">
        <div className="absolute bottom-0 left-10">
          <img src={banner_ellipsegroup_1} alt="ellipse_group" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
