import { Footer, NavBar } from "components/components";
import About from "sections/about";
import Banner from "sections/banner";
import ChooseUs from "sections/chooseUs";
import HomeSection from "sections/homeSection";
import Pricing from "sections/pricing";

function App() {
  return (
    <div>
      <NavBar />
      <HomeSection />
      <About />
      <Pricing />
      <ChooseUs />
      <Banner />
      <Footer />
    </div>
  );
}

export default App;
