import { handleClick } from "helpers/helpers";

interface InputWithButtonProps {
  className?: string;
}

const InputWithButton: React.FC<InputWithButtonProps> = (props) => {
  const { className } = props;
  return (
    <div
      className={`${className} border border-borderColor rounded-lg pl-5 h-12 pr-[2px] lg:w-96 flex items-center justify-between`}
    >
      <input
        placeholder="Your Email Address"
        className="focus:!outline-none w-full bg-transparent"
        type="text"
      />
      <button
        onClick={handleClick}
        className="bg-primary text-white p-2 rounded-lg"
      >
        Subscribe
      </button>
    </div>
  );
};

export default InputWithButton;
