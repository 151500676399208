import { useState } from "react";
import { PricingPlans } from "constant/pricingPlans";
import { PricingPlanCard } from "components/components";

const Pricing: React.FC = () => {
  const [selected, setSelected] = useState<string>("Monthly");
  const [data, setData] = useState(PricingPlans);
  const handlePlan = (i: number) => {
    let update = [...data];
    update.map((val, ind) => {
      if (i === ind) {
        val.isChoosen = true;
      } else {
        val.isChoosen = false;
      }
    });
    setData(update);
  };
  return (
    <div id="pricing" className="myContainer lg:!py-36 !py-16 bg-themeWhite">
      <p className="text-primary text-center">Pricing</p>
      <div className="flex justify-center">
        <p className="semibold lg:text-5xl text-3xl md:w-[650px] lg:leading-[70px] leading-10 text-center mt-5">
          Comfortable Pricing Plan for Everyone
        </p>
      </div>
      <div className="flex justify-center">
        <p className="lg:text-lg text-black-500 regular mt-5 md:w-[650px] text-center">
          Flexible Protection, Transparent Pricing to Safeguarding Your Assets
          Without Hidden Costs
        </p>
      </div>
      <div className="flex justify-center mt-10">
        <div className="bg-white rounded-md p-1 border-[.6px] border-[#D7CEEB]">
          {["Monthly", "Yearly"].map((e, i) => {
            return (
              <button
                key={i}
                onClick={() => setSelected(e)}
                className={`py-4 px-9 rounded-md ${
                  selected === e ? "bg-primary text-white" : "text-black-500"
                }`}
              >
                {e}
              </button>
            );
          })}
        </div>
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5 mt-7">
        {data.map((e, i) => {
          return (
            <PricingPlanCard
              key={i}
              data={e}
              handlePlan={() => handlePlan(i)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Pricing;
