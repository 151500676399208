import {
  database,
  link_external,
  money,
  presentation,
  scanning_icon_2,
  shield,
} from "assets/icons/icons";

export const Features = [
  {
    title: "Proactive Defense",
    description:
      "Forget reactive firewalls. We take security active, with real-time shields defending your data and blocking every unauthorized touch.",
    icon: shield,
  },
  {
    title: "Unmatched Expertise",
    description:
      "Backed by a team of cybersecurity experts, No Ransom Shield brings years of experience to the table.",
    icon: link_external,
  },
  {
    title: "Post-Breach Resilience",
    description:
      "No Ransom Shield guarantees the privacy and integrity of your information even after a breach",
    icon: scanning_icon_2,
  },
  {
    title: "User-Friendly Interface",
    description:
      "Our intuitive interface ensures that implementing and managing our solution is hassle-free",
    icon: presentation,
  },
  {
    title: "Transparent Pricing",
    description:
      "Enjoy peace of mind with our transparent and competitive pricing model. No hidden costs—just straightforward",
    icon: money,
  },
  {
    title: "Continuous Innovation",
    description:
      "As cyber threats evolve, so do we. No Ransom Shield is committed to staying ahead of the curve",
    icon: database,
  },
];
