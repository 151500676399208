interface ThemeButtonProps {
  content: any;
  bgFill?: boolean;
  className?: string;
  onClick?: () => void;
}

const ThemeButton: React.FC<ThemeButtonProps> = (props) => {
  const { content, bgFill, className, onClick } = props;
  return (
    <button
      onClick={onClick}
      className={`${className} ${
        bgFill ? "bg-primary text-white" : "bg-white text-primary"
      } border-2 border-primary rounded-lg px-6 py-3`}
    >
      {content}
    </button>
  );
};

export default ThemeButton;
