import { ThemeButton } from "components/components";
import { handleClick } from "helpers/helpers";

interface PricingPlanCardProps {
  data: {
    key: number;
    title: string;
    price: string;
    subtitle: string;
    description: string;
    isChoosen: boolean;
  };
  handlePlan: (data: any) => void;
}

const PricingPlanCard: React.FC<PricingPlanCardProps> = (props) => {
  const { data, handlePlan } = props;
  return (
    <div
      onClick={handlePlan}
      className={`${
        data.isChoosen && "bg-primary text-white"
      } border-[1px] flex flex-col items-center border-borderColor py-8 px-6 rounded-lg cursor-pointer`}
    >
      <p
        className={`${
          data.isChoosen ? "text-white" : "text-primary"
        } text-lg semibold`}
      >
        {data.title}
      </p>
      <p className="mt-7 semibold text-6xl">{data.price}</p>
      <p className="text-sm mt-3">Per Month</p>
      <p
        className={`${
          data.isChoosen ? "text-white" : "text-black-500"
        }  text-xs regular w-40 text-center mt-3`}
      >
        {data.subtitle}
      </p>
      <p
        className={`${
          data.isChoosen ? "text-white" : "text-black-300"
        }  semibold text-sm w-48 text-center mt-16`}
      >
        {data.description}
      </p>
      <ThemeButton
        onClick={handleClick}
        content={"Subscribe Now"}
        bgFill={data.isChoosen ? false : true}
        className="mt-7"
      />
    </div>
  );
};

export default PricingPlanCard;
