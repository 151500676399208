export const PricingPlans = [
  {
    key: 1,
    title: "Free Plan",
    price: "$0.0",
    subtitle: "AI STUDIO CONFIDENTIAL MLOps STARTER",
    description: "CONFIDENTIAL AI STARTER TIER 5-SEAT MIN",
    isChoosen: false,
  },
  {
    key: 2,
    title: "Starter Plan",
    price: "$249",
    subtitle: "AI STUDIO CONFIDENTIAL MLOps STARTER",
    description: "CONFIDENTIAL AI STARTER TIER 5-SEAT MIN",
    isChoosen: false,
  },
  {
    key: 3,
    title: "Medium Plan",
    price: "$880",
    subtitle: "AI STUDIO CONFIDENTIAL MLOps MEDIUM",
    description: "CONFIDENTIAL AI MEDIUM TIER 2-SEAT MIN",
    isChoosen: true,
  },
  {
    key: 4,
    title: "Enterprise Plan",
    price: "$1200",
    subtitle: "ENTERPRISE & ENTERPRISE+",
    description: "CONFIDENTIAL AI, RAY, KUBEFLOW ENTERPRISE TIER 5-SEAT MIN",
    isChoosen: false,
  },
];
