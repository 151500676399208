import { earth_icon, mail_icon, phone_icon } from "assets/icons/icons";
import { honeypotz, logo } from "assets/images/images";
import { InputWithButton } from "components/components";

const Footer: React.FC = () => {
  return (
    <div className="bg-themeWhite myContainer !py-16">
      <div className="lg:grid grid-cols-11 gap-5 pb-10">
        <div className="col-span-4">
          <img alt="logo" src={logo} width={130} />
          <p className="regular text-sm mt-5">
            For Latest Update and offer news please subscribe to our site
          </p>
          <InputWithButton className="mt-10" />
        </div>
        <div></div>
        <div className="col-span-2 lg:mt-0 mt-10">
          <p className="semibold pb-3">Company</p>
          <div className="regular text-sm flex flex-col leading-9">
            <a href="#about">About Us</a>
            <a href="#pricing">Pricing</a>
            <a>Help</a>
            <a href="#contact_us">Contact Us</a>
          </div>
        </div>
        <div className="col-span-2 lg:mt-0 mt-10">
          <p className="semibold pb-3">About Us</p>
          <div className="regular text-sm flex flex-col leading-9">
            <a>Payment Plan</a>
            <a>Talk To US</a>
            <a>FAQ</a>
          </div>
        </div>
        <div className="col-span-2 lg:mt-0 mt-10">
          <p className="semibold pb-3">Contact US</p>
          <p className="regular text-sm">8 The Green, Ste R Dover , DE 19901</p>
          <div className="flex items-center mt-5">
            <img alt="phone" src={phone_icon} className="mr-2" />
            <p className="text-sm">1 305 390 0563</p>
          </div>
          <div className="flex items-center my-5">
            <img alt="phone" src={mail_icon} className="mr-2" />
            <p className="text-sm">team@honeypotz.net</p>
          </div>
          <div className="flex items-center">
            <img alt="phone" src={earth_icon} className="mr-2" />
            <a
              href="https://www.honeypotz.net/"
              target="_blank"
              className="text-sm"
            >
              www.honeypotz.net
            </a>
          </div>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col items-center justify-between border-t-[1px] border-gray-200 pt-8">
        <div className="flex items-center">
          <p className="text-black-500 regular text-sm">Developed By:</p>
          <img alt="honeypotz" src={honeypotz} width={130} className="ml-3" />
        </div>
        <p className="text-black-500 regular text-sm mt-1 lg:mt-0">
          © 2024 noransom, All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
